import React from "react";

const Features = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Checkout Our <span>Dental Software</span> <br /> Capabilities Below.
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                <h3>
                  <a href="#none">
                    Dental <br /> Software & Apps
                  </a>
                </h3>
                <p>
                  We will build any <br /> dental software, apps or <br /> solution for
                  your practices' needs.
                </p>
                <a href="mailto:hello@leadplotter.com" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                <h3>
                  <a href="#none">
                    Practice<br /> Analysis
                  </a>
                </h3>
                <p>
                  We can build tools to <br /> analyze your Dental Practice<br /> metrics and 
                  performance.
                </p>
                <a href="mailto:hello@leadplotter.com" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  <a href="#none">
                    Dental Website, SEO & <br /> Backlinks
                  </a>
                </h3>
                <p>
                  We'll put your dental offices' <br />on the map and in front  <br /> of more
                  patients and eyeballs.
                </p>
                <a href="mailto:hello@leadplotter.com" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  <a href="#none">
                    Any Dental Ideas <br /> You Have
                  </a>
                </h3>
                <p>
                  Yes, we will turn your <br /> imagination and ideas <br /> into 
                  reality.
                </p>
                <a href="mailto:hello@leadplotter.com" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
