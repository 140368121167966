import React from "react";
import bgImage from "../assets/images/background/cta-one-bg.png";
import mocImage from "../assets/images/mocs/cta-moc-1-1.png";
const CallToAction = () => {
  return (
    <section className="cta-one">
      <img src={bgImage} className="cta-one__bg" alt="alter text" />
      <div className="container">
        <img src={mocImage} className="cta-one__moc" alt="alter text" />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  We build Powerful <span>Dental Software</span> for Dental Offices.
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  Have a problem in your dental office? Wish there was a software solution for it? We have you covered:
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i>We first listen and hear your issues and concerns.
                </li>
                <li>
                  <i className="fa fa-check"></i>We discuss options so you can make a sound and informed decision.
                </li>
                <li>
                  <i className="fa fa-check"></i>We build and implement the solutions for your dental practice.
                </li>
              </ul>
              <a href="mailto:hello@leadplotter.com" className="thm-btn">
                <span>Message Us Now!</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
