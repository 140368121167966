import React, { Component } from "react";

export default class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      month: true,
      year: false
    };
  }

  handleToggle = () => {
    const month = this.state.month;
    const year = this.state.year;

    if (month) {
      this.setState({ year: true, month: false });
    }
    if (year) {
      this.setState({ year: false, month: true });
    }
  };

  render() {
    return (
      <section className="pricing-one" id="pricing">
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title">
              Some Examples of our <span>Dental Software</span> Built <br /> for Dentists and Dental Specialists.
            </h2>
          </div>
          <ul
            className="list-inline text-center switch-toggler-list"
            id="switch-toggle-tab"
          >
            <li className={this.state.month ? "month active" : "month"}>
              <a href="#none">Monthly</a>
            </li>
            <li>
              <div
                className={this.state.year ? "switch off" : "switch on"}
                role="button"
                tabIndex="-1"
                onClick={this.handleToggle}
                onKeyDown={this.handleToggle}
              >
                <span className="slider round"></span>
              </div>
            </li>
            <li className={this.state.year ? "year active" : "year"}>
              <a href="#none">Yearly</a>
            </li>
          </ul>
          <div className="tabed-content">
            {this.state.month === true ? (
              <div id="month">
                <div className="row pricing-one__price-row">
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$8.25</h3>
                      <p>LeadPlotter.com for Dental Specialists</p>
                      <ul className="list-unstyled">
                        <li>Finds New Referring Dentists</li>
                        <li>Manages your Dental Referrals</li>
                        <li>Plots your Dental Referrals on a Map</li>
                        <li>Identifies Best and Worst Referrals</li>
                      </ul>
                      <a href="https://leadplotter.com" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInDown"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$25</h3>
                      <p>FlowCharts.ai for Dental Practices & DSO's</p>
                      <ul className="list-unstyled">
                        <li>Conducts patient dynamic surveys</li>
                        <li>Requests Google, Facebook and Yelp Reviews from Dental Patients</li>
                        <li>Speeds up intake of Dental Patients</li>
                        <li>Facilitates and Expedites Chart Writing</li>
                      </ul>
                      <a href="https://flowcharts.ai" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$</h3>
                      <p>Your Software Goes Here</p>
                      <ul className="list-unstyled">
                        <li>Have a dental problem?</li>
                        <li>Want a software for your practice?</li>
                        <li>Want a website or mobile app?</li>
                        <li>Shoot us a message!</li>
                      </ul>
                      <a href="#none" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.year === true ? (
              <div id="year">
                <div className="row pricing-one__price-row">
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$99</h3>
                      <p>LeadPlotter.com for Dental Specialists</p>
                      <ul className="list-unstyled">
                        <li>Finds New Referring Dentists</li>
                        <li>Manages your Dental Referrals</li>
                        <li>Plots your Dental Referrals on a Map</li>
                        <li>Identifies Best and Worst Referrals</li>
                      </ul>
                      <a href="https://leadplotter.com" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInDown"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$300</h3>
                      <p>FlowCharts.ai for Dental Practices & DSO's</p>
                      <ul className="list-unstyled">
                        <li>Conducts patient dynamic surveys</li>
                        <li>Requests Google, Facebook and Yelp Reviews from Dental Patients</li>
                        <li>Speeds up intake of Dental Patients</li>
                        <li>Facilitates and Expedites Chart Writing</li>
                      </ul>
                      <a href="https://flowcharts.ai" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 animated fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div className="pricing-one__single text-center">
                      <h3>$</h3>
                      <p>YOUR SOFTWARE GOES HERE</p>
                      <ul className="list-unstyled">
                        <li>Have a dental problem?</li>
                        <li>Want a software for your practice?</li>
                        <li>Want a website or mobile app?</li>
                        <li>Shoot us a message!</li>
                      </ul>
                      <a href="#none" className="thm-btn pricing-one__btn">
                        <span>Launch</span>
                      </a>
                      <span className="tag-line">No hidden charges!</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
