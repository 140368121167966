import React from "react";
import AccordionItem from "./AccordionItem";
import bgImage from "../assets/images/background/faq-one-bg.png";
const Faq = () => {
  return (
    <section className="faq-one">
      <img src={bgImage} alt="alter text" className="faq-one__bg" />
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Want to Ask <span>Something</span> <br /> From Us?
          </h2>
        </div>
        <div className="accrodion-grp faq-accrodion">
        <AccordionItem
            title="How can I get help with my dental project?"
            content="Shoot us a message at hello@LeadPlotter.com with a project
            you need help with and we'll connect to get the ball rolling!"
            status={true}
          />
          <AccordionItem
            title="What types of dental projects do you do?"
            content="We do it all! From Facebook/Instgram Marketing, CRM, 
            dental practice management software, website development and design,
            Dental Logos, Dental Stationary, SEO, Facebook & Instagram Marketing, 
            Postcards, Swag, Gift Baskets, Patient Software, Dental Practice Metrics. 
            Dental Mobile App Development, Web Apps. You name it, we'll do it!"
            status={false}
          />
          <AccordionItem
            title="What kind of dental practices do you serve?"
            content="We serve them all including family dentists, specialists like orthodontics, 
            pedodontics, oral surgery, endodontics, periodontics and prosthodontics. They vary from solo
            dentists to DSO's."
            status={false}
          />
        </div>
      </div>
    </section>
  );
};
export default Faq;
